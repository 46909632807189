exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-photos-jsx": () => import("./../../../src/pages/photos.jsx" /* webpackChunkName: "component---src-pages-photos-jsx" */),
  "component---src-templates-photo-index-jsx": () => import("./../../../src/templates/PhotoIndex.jsx" /* webpackChunkName: "component---src-templates-photo-index-jsx" */),
  "component---src-templates-photo-jsx": () => import("./../../../src/templates/Photo.jsx" /* webpackChunkName: "component---src-templates-photo-jsx" */)
}

